import { Session } from 'next-auth';

export interface IDefaultPageProps {
  session: Session | null;
  error?: {
    message: string;
  }
}

declare module 'next-auth' {
  interface Session {
    accessToken?: string;
    expires_at: number;
  }

  interface User {
    access_token: string;
    expires_at: string;
    id_token: string;
    refresh_token: string;
  }


  interface Account {
    access_token: string;
    expires_at: string;
    id_token: string;
    refresh_token: string;
  }

  interface JWT {
    access_token?: string;
  }
}

export const ApolloErrorCookie = 'APOLLO_ERROR';
